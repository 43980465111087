import styled from '@emotion/styled/macro'

interface DropdownToggleProps {
  isActive: boolean
  hasSelection: boolean
  isSingleOption: boolean
}

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 250px;
  border-radius: 10px;
  margin-top: 1px;
`

export const DropdownToggle = styled.button<DropdownToggleProps>`
  padding: 13px 45px 13px 15px;
  cursor: ${({ isSingleOption }) => (isSingleOption ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isSingleOption }) => (isSingleOption ? 'none' : 'auto')};
  background-color: ${({ isSingleOption }) => (isSingleOption ? '#dddddd' : '#ffffff')};
  border: none;
  font-size: 16px;
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;

  .dropdown-icon {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease;
  }
`

export const DropdownMenu = styled.ul`
  position: absolute;
  border-radius: 8px;
  border-bottom-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-top: none;
  display: block;
  z-index: 1000;
  padding: 0;
  list-style-type: none;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const DropdownMenuEl = styled.li`
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  width: 90%;
  margin: 0 auto;

  &:hover {
    background: #f5f5f5;
    border-radius: 8px;
  }
`

export const DropdownInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  height: 100%;
  font-family: inherit;
`
