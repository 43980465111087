import styled from '@emotion/styled/macro'

export const TariffsTitle = styled.h2`
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  color: #223361;
`

export const TooltipContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 20px;
  color: #1b2559;
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 4px;
  letter-spacing: -0.36px;
  position: relative;

  img {
    width: 15px;
  }

  &.license-title {
    min-height: 63px;
    display: block;
    margin-bottom: 16px;

    &.short-title {
      img {
        display: inline;
        position: relative;
        top: 2px;
      }
    }

    @media (max-width: 768px) {
      min-height: 40px;
    }
  }
`

export const TextPrice = styled.p`
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #232323;
  display: flex;
  justify-content: center;
  align-items: center;

  &.tariffs-text {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &.license-text {
    margin-top: 4px;
    margin-bottom: 16px;
  }

  &.packs-text {
    margin-top: 4px;
    margin-bottom: 16px;
  }
`

export const CardsContainer = styled.div`
  margin: 32px 0 0 0;

  @media (max-width: 1275px) {
    margin: 32px 0 0 0;
  }

  .slick-slide {
    padding: 0 12px;

    @media (max-width: 1275px) {
      padding: 0 6px;
    }

    &[data-index='0'] > div > div {
      border: 1px solid #223361;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }
  }

  .slick-track {
    margin-left: 0;
  }

  &.card-container-tariffs {
    @media (max-width: 768px) {
      position: sticky;
      top: -24px;
      z-index: 2;
      background: #f7f9fd;
      padding-bottom: 50px;
      margin-bottom: -50px;
    }
  }
`

export const TariffsCard = styled.div<{ bgImage?: string }>`
  background-color: #ffffff;
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-repeat: no-repeat;
  background-size: 5vw;
  background-position: right 10px;
  padding: 24px 16px;
  border-radius: 8px;
  text-align: center;
  min-width: 176px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    min-width: auto;
  }

  &.plan {
    &:nth-child(1) {
      border: 1px solid #223361;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }
  }

  &.license {
    width: 176px;

    button {
      margin-top: 16px;
    }

    &:last-child {
      margin-right: 24px;
    }
  }

  &.hide {
    display: none !important;
  }

  &.packs-card {
    min-height: 200px;
  }

  @media (max-width: 768px) {
    background-size: 100px;
    min-height: 185px;
  }
`

export const TogglesContainer = styled.div`
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 48px;
  }
`

export const ToggleBlock = styled.div<{ 'data-count'?: number }>`
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;

  &.favourite:before {
    content: '';
    width: ${({ 'data-count': dataCount }) => (dataCount ? `calc(100% / ${dataCount} - 12px)` : '100%')};
    height: 100%;
    background-color: rgba(217, 217, 217, 0.43);
    position: absolute;
    left: 0;
    top: 0;
    max-width: 300px;
    min-width: 230px;

    @media (max-width: 768px) {
      content: none;
    }
  }

  &[data-index='0'] {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(217, 217, 217, 0.43);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
  }
`

export const ToggleMath = styled.div`
  width: 100%;
  height: 24px;
  position: relative;
  align-items: center;
  display: inline-flex !important;
  justify-content: center;
  gap: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  color: #1b2559;
  min-width: 230px;
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: initial;
  }

  img {
    width: 15px;
  }

  &.plus {
    &:before {
      content: '';
      width: 2px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: calc(50% - 5px);
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #223361;
    }

    &:after {
      content: '';
      width: 18px;
      height: 2px;
      position: absolute;
      top: calc(50% - 3px);
      left: calc(50% + 4px);
      transform: translate(-50%, -50%) rotate(131deg);
      background-color: #223361;
    }
  }

  &.minus {
    &:after {
      content: '';
      width: 14px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #223361;
    }
  }

  &.text {
    height: 100%;
    width: 100%;
    font-size: 12px;
    padding: 16px;
    text-align: center;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 230px;

    span {
      position: relative;
      color: #000000;
      padding-left: 10px;

      &:before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000000;
      }
    }
  }
`

export const ToggleMore = styled.button`
  background: #ffffff;
  border: none;
  width: 100%;
  border-radius: 8px;
  padding: 7.25px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #223361;

  &.rotate {
    img {
      transform: rotate(180deg);
    }
  }
`

export const LicenseContainer = styled.div`
  margin-top: 60px;
`

export const LicenseGroup = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 24px;
  width: calc(100vw - 311px - 8.1rem);
  overflow-x: scroll;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    width: calc(100vw - 311px - 1.6rem);
  }

  @media (max-width: 768px) {
    width: 100%;
    display: block;
    overflow: inherit;
  }

  &::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    background: #ffffff; /* цвет зоны отслеживания */
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee; /* цвет бегунка */
    border-radius: 40px; /* округлось бегунка */
    border: 1px solid #eee; /* отступ вокруг бегунка */
  }

  .slick-slide {
    padding: 0 6px;
  }
`

export const SmallText = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #232323;
  text-align: center;

  svg {
    position: relative;
    top: 1px;
  }

  &.packs-info-text {
    margin-top: 16px;
  }
`

export const PacksContainer = styled.div`
  margin-top: 60px;

  .slick-slide {
    padding: 0 6px;
  }

  .support-mobile {
    margin-top: 30px;
  }

  .slick-slider {
    @media (max-width: 768px) {
      margin-bottom: 48px;
    }
  }
`

export const FullContainer = styled.div`
  width: calc(100vw - 311px - 8.1rem);
  overflow-x: auto;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: grid;

  @media (max-width: 1000px) {
    width: calc(100vw - 311px - 1.6rem);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    background: #ffffff; /* цвет зоны отслеживания */
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee; /* цвет бегунка */
    border-radius: 40px; /* округлось бегунка */
    border: 1px solid #eee; /* отступ вокруг бегунка */
  }
`

export const PacksGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 32px;

  &.packs-group {
    position: sticky;
    top: 0;
  }
`

export const PacksPriceGroup = styled.div`
  background-color: #ffffff;
  padding: 16px;
  margin-top: 24px;
  border-radius: 8px;
  width: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const MediumTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: #232323;
  max-width: 500px;
`

export const PacksPrice = styled.div`
  min-width: 175px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: #232323;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  &.hide {
    display: none !important;
  }
`

export const PacksPrices = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
  margin-left: -16px;
  margin-right: -16px;
`

export const SeoContainer = styled.div`
  margin-top: 60px;

  .slick-slide {
    padding: 0 6px;
  }

  .seo-slider {
    margin: 32px -6px 0 -6px;
  }

  .seo-more {
    margin-top: 16px;
  }
`

export const SeoInfo = styled.div`
  padding: 16px;
  background-color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 24px;
  border-radius: 8px;

  .seo-time {
    margin-top: 24px;
  }
`

export const SeoGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  margin-top: 24px;
  // position: sticky;
  // top: -50px;
  background-color: #f7f8fa;
  // padding-bottom: 10px;

  @media (max-width: 768px) {
    display: block;
    margin: 30px -6px 48px -6px;
    padding-bottom: 60px;
    top: -20px;
  }
`

export const TariffsDesktop = styled.div`
  display: grid;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    background: #ffffff; /* цвет зоны отслеживания */
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee; /* цвет бегунка */
    border-radius: 40px; /* округлось бегунка */
    border: 1px solid #eee; /* отступ вокруг бегунка */
  }

  @media (max-width: 1400px) {
    width: calc(100vw - 311px - 8.1rem);
    overflow-x: scroll;
    padding-bottom: 16px;
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 311px - 1.6rem);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .cards-desktop {
    display: flex;
    gap: 24px;
    position: sticky;
    top: -50px;
    z-index: 2;
    background: #f7f8fa;
    padding-bottom: 10px;

    .plan {
      min-height: 185px;
      min-width: 230px;
      max-width: 300px;
      width: 100%;
    }
  }
`

export const SeoDesktop = styled.div`
  display: grid;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
  }

  &::-webkit-scrollbar-track {
    background: #ffffff; /* цвет зоны отслеживания */
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee; /* цвет бегунка */
    border-radius: 40px; /* округлось бегунка */
    border: 1px solid #eee; /* отступ вокруг бегунка */
  }

  @media (max-width: 1400px) {
    width: calc(100vw - 311px - 8.1rem);
    overflow-x: scroll;
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 311px - 1.6rem);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const SeoInfoGroup = styled.div`
  display: grid;

  .packs-seo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
`

export const SupportDesktop = styled.div``

export const TooltipGroup = styled.div<{ isInContainer?: boolean }>`
  display: flex;
  align-items: center;

  img {
    filter: invert(47%) sepia(66%) saturate(308%) hue-rotate(134deg);
  }

  p {
    display: none;
    position: absolute;
    font-size: 12px;
    width: 100%;
    bottom: 50%;
    right: ${({ isInContainer }) => !isInContainer && '0'};
    color: #000000;
    line-height: 14px;
    background: #ffffff;
    border: 1px solid #223361;
    border-radius: 5px;
    padding: 5px;
    z-index: 2;
    transform: translateY(50%);

    @media (max-width: 768px) {
      top: calc(100% + 5px);
      bottom: auto;
      width: max-content;
      left: ${({ isInContainer }) => !isInContainer && '50%'};
      transform: translateX(-50%);
      max-width: 100%;
    }
  }

  &:hover {
    p {
      display: block;
    }
  }

  &.inline {
    display: inline-flex;
    ${({ isInContainer }) => (isInContainer ? 'position: relative;' + 'img {width: 15px;}' : '')}

    p {
      top: -24px;
      bottom: auto;
      transform: none;
      left: ${({ isInContainer }) => !isInContainer && '-15px'};
      width: ${({ isInContainer }) => (isInContainer ? '300px' : 'calc(100% + 30px)')};

      @media (min-width: 1025px) {
        z-index: 9;
        left: ${({ isInContainer }) => isInContainer && '16px'};
        width: ${({ isInContainer }) => isInContainer && '330px'};
        min-width: ${({ isInContainer }) => isInContainer && '330px'};
      }

      @media (max-width: 1024px) {
        z-index: 9;
        top: ${({ isInContainer }) => isInContainer && '0'};
        right: ${({ isInContainer }) => isInContainer && '20px'};
        width: ${({ isInContainer }) => isInContainer && '330px'};
        min-width: ${({ isInContainer }) => isInContainer && '330px'};
      }

      @media (max-width: 768px) {
        left: ${({ isInContainer }) => !isInContainer && '0'};
      }

      @media (max-width: 375px) {
        width: ${({ isInContainer }) => isInContainer && '290px'};
        min-width: ${({ isInContainer }) => isInContainer && '290px'};
      }
    }

    @media (max-width: 768px) {
      margin-left: 10px;
    }
  }
`

export const PreSelectBlock = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 16px;
  font-size: 16px;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  button {
    @media (max-width: 768px) {
      width: 100%;
      max-height: 40px;
      min-height: 40px;
    }
  }
`

export const ArchiveGroup = styled.div<{ bold?: boolean; border?: boolean; margin?: boolean }>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  border-bottom: ${({ border }) => (border ? '1px solid #C4C4C4' : 'none')};
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  margin-top: ${({ margin }) => (margin ? '24px' : '0')};
`

export const ModalButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 6px;
  }
`
