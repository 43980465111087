import styled from '@emotion/styled/macro'

export const ContainerGlobal = styled.div`
  margin-top: -40px;
  position: relative;
`

export const Header = styled.div`
  background: #fff;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;

  .react-datepicker__view-calendar-icon input {
    padding: 5px 10px 5px 10px;
    width: 100%;
    border: none;
  }

  .react-datepicker-wrapper {
    max-width: 160px;
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const DateGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .react-datepicker-wrapper {
    max-width: 100%;
    width: 100% !important;

    .datePicker-metric {
      width: 100%;
      border-radius: 10px;
      padding-top: 11px;
      padding-bottom: 11px;
      height: 100%;
      border: 1px solid #ddd !important;
    }

    .react-datepicker__calendar-icon {
      fill: white;
      stroke: #808080;
      stroke-width: 20;
    }
  }

  .react-datepicker__calendar-icon {
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    right: 10px;
    pointer-events: none;
    fill: #223361;
  }
`

export const HeaderFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;
`

export const DateGroupFields = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;

  button {
  width: 100%;
  @media (max-width: 768px) {
      min-width: 74px;
  }

  .inactive {
    color: #808080;
    border: 1px solid #ddd;
  }
`

export const DateGroupInputs = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  min-width: 260px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`

export const HeaderTitle = styled.span`
  color: #ffffff;
  font-size: 15px;
`

export const Body = styled.div`
  position: relative;
  border-radius: 10px;
  // overflow: hidden;

  #heatmapContainer {
    position: absolute !important;
    top: 0;
    left: 0;
    background-color: #00000057;

    &.loading {
      background-color: #000000d4;
    }
  }
`

export const EmptyBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 2;
`

export const EmptyTitle = styled.h3`
  font-size: 2vw;
  text-align: center;
`

export const LoaderBody = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #00000094;

  > div > div {
    border-color: #ffffff;
  }
`

export const ActionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const InteractionBtn = styled.button``
